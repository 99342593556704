import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopifyServiceService } from '../service/shopify-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  shopName = "";
  year = 0;
  constructor(private router: Router,private shopifyService: ShopifyServiceService) { }

  privacypolicy(){
    this.router.navigate(['/legal/privacypolicy']);
  }

  shipping(){
    this.router.navigate(['/legal/shippingpolicy']);
  }

  refund(){
    this.router.navigate(['/legal/returnpolicy']);
  }

  terms(){
    this.router.navigate(['/legal/termsofuse']);
  }

  about(){
    this.router.navigate(['/about']);
  }

  contact(){
    this.router.navigate(['/contactus']);
  }

  ngOnInit(): void {
    if(localStorage.getItem('FFS_Shop')) {
      var res  = JSON.parse(localStorage.getItem('FFS_Shop'));
      this.shopName = res.data.shop.name;
    }
    else{
      this.shopifyService.getCurrentShop().then((res) => {
        localStorage.setItem('FFS_Shop', JSON.stringify(res));
        this.shopName = res.data.shop.name;
      }, err=> console.log(err));
    }
    // this.shopifyService.getCurrentShop().then((res) => {
    //   this.shopName = res.data.shop.name;

    //   // console.log(res.data.shop.name);
    // }, err=> console.log(err));

    this.year = new Date().getFullYear();
  }

}
