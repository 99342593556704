<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="copyRightText">
                    &copy; Copyright {{year}}, {{shopName}}
                </div>
            </div>
            <div class="col-md-auto">
                <div class="footerRightLink">
                    <a href="javascript:void(0)" (click)="privacypolicy()">Privacy Policy </a>
                    <a href="javascript:void(0)" (click)="terms()">Terms of Service</a>
                    <a href="javascript:void(0)" (click)="shipping()">Shipping Policy</a>
                    <a href="javascript:void(0)" (click)="refund()">Return Policy</a>
                    <a href="javascript:void(0)" (click)="about()">About</a>
                    <a href="javascript:void(0)" (click)="contact()">Contact</a>
                </div>
            </div>
        </div>
    </div>
</footer>
