export const environment = {
  production: false,
  url: 'https://admin.familyfraternal.com/api/graphql',
  urljson: 'https://admin.familyfraternal.com/api/graphql.json',
  shopifyaccesstoken: '14cdfdca830171c181fee20ff1fdb8b5',
  shopifydomain: 'https://admin.familyfraternal.com',
  adminurl: 'https://0120fc43547a3877bd94c08be9d9a281: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@family-fraternal-sales-ltd.myshopify.com',
  shopifyAPIkey: '0120fc43547a3877bd94c08be9d9a281',
  shopifypassword: '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',
  shopifyshop: 'Family Fraternal Sales, LTD',
  timeout: 3000,
  MailgunDomain: 'mg.familyfraternal.com',
  MailgunAPIKEY: 'key-14e48e5163a8bfd377b343091d023f5c',
  EmailFrom: 'nonreply@familyfraternity.com',
  EmailTo: 'nyoessupplies@aol.com',
};
