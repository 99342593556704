import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import { ShopifyServiceService } from '../../service/shopify-service.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  firstName = "";
  lastName = "";
  Email = "";
  Message = "";

  constructor(private toastr: ToastrService,private http: HttpClient,private shopifyService: ShopifyServiceService) { }

  _getHeaders() {
    var mailgunApiKey = window.btoa("api:key-ca51af067e7398ab0ee2e15e809a2653");
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    httpHeaders.append('Authorization',"Basic " + mailgunApiKey);
    let options = {
      headers: httpHeaders
    };

    return options
  }

  OnSubmit(){
    var name = this.firstName + " " + this.lastName;

    var msg = `Hi,
               First Name : `+this.firstName+`
               Last Name : `+this.lastName+`
               Email : `+this.Email+`
               Message : `+this.Message+`
               `;
   
               var obj = 'from='+environment.shopifyshop+'<'+environment.EmailFrom+'>&to='+environment.EmailTo+'&subject=Contact US < '+name+' >&text='+msg;
               //var obj = 'from=Family Fraternal Sales, LTD<nonreply@familyfraternity.com>&to=family.fraternal@gmail.com&subject=Contact US < '+name+' >&text='+msg;

   
    this.shopifyService.SendMail(obj).subscribe(res => { 
      $("html, body").animate({ scrollTop: 0 }, "slow");
      this.firstName = "";
      this.lastName = "";
      this.Email = "";
      this.Message = "";
      this.toastr.success("Thank you!", 'Your message has been successfully sent. We will contact you very soon!', {
        timeOut: environment.timeout,
        closeButton : true
      });
    });
    
  }

  FocusOutEvent(event: any){
    if ($(event.target).val() !== '') {
      $(event.target).parent().find('label').addClass('active');
    } else {
      $(event.target).parent().find('label').removeClass('active');
    }
  }

  ngOnInit(): void {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

}
