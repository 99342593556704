import { Injectable } from '@angular/core';
import GraphQLJSClient from 'graphql-js-client';
import {environment} from "../../environments/environment";
import typeBundle from './types';
import { Product } from '../models/product.model';
import { Address } from '../models/address.model';
import { Collection } from '../models/collection.model';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import Client from 'shopify-buy';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ShopifyServiceService {

  _client = Client.buildClient({
    domain: environment.shopifydomain,
    storefrontAccessToken: environment.shopifyaccesstoken
  });

  client = new GraphQLJSClient(typeBundle, {
    url: environment.url,
    fetcherOptions: {
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      }
    }
  });

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.url;

  getCurrentShop(): Promise<any> {
    let client = this.client;

    let query = client.query((root) => {
      root.add('shop', (shop) => {
        shop.add('name'),
        shop.add('privacyPolicy', (sub) => {
          sub.add('body');
          sub.add('title');
        }),
        shop.add('termsOfService', (sub) => {
          sub.add('body');
          sub.add('title');
        }),
        shop.add('refundPolicy', (sub) => {
          sub.add('body');
          sub.add('title');
        })
      })
    });
    return client.send(query);
  }

  getCurrentShopNew(): Promise<any> {

    return $.ajax({
      method: "POST",
      url: environment.urljson,
      contentType: "application/json",
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      },
      data: JSON.stringify({
        query: `{
          shop {
            name
            privacyPolicy {
              body
              title
            }
            refundPolicy {
              body
              title
            }
            shippingPolicy {
              body
              title
            }
            termsOfService {
              body
              title
            }
          }
        }
        `
      })
})


  }

  getProductById(_id): Promise<any> {
    let client = this.client;

    let query = client.query((root) => {
      root.add('node', { args: { id: _id }, alias: 'product' }, (node) => {
        node.add('id');
        node.addInlineFragmentOn('Product', (Product) => {
          Product.add('title');
          Product.add('createdAt');
          Product.add('description');
          Product.add('productType');
          Product.add('publishedAt');
          Product.add('tags');
          Product.add('updatedAt');
          Product.add('vendor');
          Product.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          // Product.addConnection('metafields', { args: { first: 15 } }, (metafields) => {
          //   metafields.add('namespace');
          //   metafields.add('value');
          //   metafields.add('key');
          // })
          Product.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            //variants.add('compareAtPrice');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return client.send(query).then(({ model, data }) => {
      return client.fetchAllPages(model.product, { pageSize: 250 })
    });

  }

  getProductByIdExtrafiled(_id) {
    //JSON.stringify();
    var query = `{
        node(id: "`+_id+`") {
          ... on Product {
            metafields(first: 250) {
              edges {
                node {
                  key
                  namespace
                  value
                  description
                }
              }
            }
            variants(first: 250) {
              edges {
                node {
                  compareAtPrice
                }
              }
            }
          }
        }
    }`;

    return $.ajax({
        method: "POST",
        url: environment.urljson,
        contentType: "application/graphql",
        headers: {
          'Content-Type': 'application/graphql',
          'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
        },
        data: query
  })

}

  create_metafiled(): Promise<any> {
    var query = {
      "metafield": {
        "namespace": "inventory",
        "key": "warehouse",
        "value": 25,
        "value_type": "integer"
      }
    }

    //Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzUwNjQ0ODc3OTY4NzU=
    return $.ajax({
      method: "POST",
      url: environment.shopifydomain + "/admin/api/2020-10/products/Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzUwNjQ0ODc3OTY4NzU=/metafields.json",
      contentType: "application/json",
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      },
      data: JSON.stringify(query)
    })
  }

  products_admin(): Promise<any> {

    //Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzUwNjQ0ODc3OTY4NzU=
//   contentType: "application/json; charset=utf-8"
    var url = environment.adminurl + "/admin/api/2020-10/products.json?fields=id,title";

    return $.ajax({
      method: "GET",
      url: url,
    })
  }

  getBuyProductById(_id) {
    this._client.product.fetch(_id).then((product) => {
      // Do something with the product
      console.log(product);
    });
  }

  getProductsTopSix(): Promise<any> {

    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
         shop.add('name');
         shop.addConnection('products', { args: { first : 6 } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop, { pageSize: 20 })
    });

  }

  getProductsFour(): Promise<any> {

    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
         shop.add('name');
         shop.addConnection('products', { args: { first : 4 } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop, { pageSize: 20 })
    });

  }

  getPages(): Promise<any> {

    return $.ajax({
      method: "POST",
      url: environment.urljson,
      contentType: "application/json",
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      },
      data: JSON.stringify({
        query: `{
          pages(first:250) {
            edges {
              node {
                body
                title
              }
            }
          }
        }
        `
      })
})


  }

  getProductsFive(): Promise<any> {

    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
         shop.add('name');
         shop.addConnection('products', { args: { first : 5 } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop, { pageSize: 20 })
    });

  }

  getProductsQueryTop(count) {
    var query = `{
            products(first: `+count+`) {
              edges {
                node {
                  title
                  id
                  tags
                  variants(first: 250) {
                    edges {
                      node {
                        compareAtPrice
                        price
                        image {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `;

      return $.ajax({
          method: "POST",
          url: environment.urljson,
          contentType: "application/graphql",
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
          },
          data: query
      })
  }

  getProductsSearch(_prefix): Promise<any> {

    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
         shop.add('name');
         shop.addConnection('products', { args: { first : 10, query:"title:"+_prefix } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop.products, { pageSize: 20 })
    });

  }

  getsortpro(){
    // return this._client.product.fetch({productsFirst: 1}).then((product) => {
    // });
  }

  getProducts(): Promise<Product[]> {
    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
        shop.addConnection('products', { args: { first: 250 } }, (products) => {
          products.add('id');
          products.add('title');
          products.add('tags');
          products.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          })
          products.addConnection('variants', { args: { first: 250 } }, (variants) => {
            variants.add('id');
            variants.add('product');
            variants.add('title');
            variants.add('price');
            variants.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            })
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop.products, { pageSize: 20 })
    });

  }

  getCollection(): Promise<Collection[]> {

    let query = this.client.query((root) => {
      root.add('shop', (shop) => {
        shop.addConnection('collections', { args: { first: 250 } }, (collections) => {
          collections.add('id');
          collections.add('title');
          collections.add('description');
          collections.add('descriptionHtml');
          collections.addConnection('products', {args: {first: 250}}, (products) => {
            products.add('id');
            products.add('title');
            products.add('tags');
            products.addConnection('collections', {args: {first: 250}}, (collections) => {
              collections.add('id');
              collections.add('title');
            })
          })
          collections.add('image', (image) => {
            image.add('src');
            image.add('id');
            image.add('altText');
          })
        })
      })
    });

    return this.client.send(query).then(({ model, data }) => {
      return this.client.fetchAllPages(model.shop.collections, { pageSize: 20 })
    });
  }

  getProductsInCollection(collectionId): Promise<Collection> {
    let query = this.client.query((root) => {
        root.add('node', {args: {id: collectionId}, alias: 'collection'}, (node) => {
            node.addInlineFragmentOn('Collection', (collection) => {
                collection.add('id');
                collection.add('title');
                collection.add('description');
                collection.add('descriptionHtml');
                collection.addConnection('products', {args: {first: 250}}, (products) => {
                    products.add('id');
                    products.add('title');
                    products.add('tags');
                    products.addConnection('variants', {args: {first: 2}}, (variants) => {
                        variants.add('price');
                        //variants.add('compareAtPrice');
                    });
                    // products.addConnection('priceRange', {args: {first: 10}}, (priceRange) => {
                    //   priceRange.add('maxVariantPrice', (maxVariantPrice) => {
                    //     maxVariantPrice.add('amount');
                    //   })
                    //   priceRange.add('minVariantPrice', (minVariantPrice) => {
                    //     minVariantPrice.add('amount');
                    //   })
                    //   //variants.add('compareAtPrice');
                    // });
                    products.addConnection('images', {args: {first: 1}}, (images) => {
                        images.add('src');
                    })
                })
            })
        })
    });

    return this.client.send(query).then(({model, data}) => {
        // returns all Pages
        //return this.shopClient.fetchAllPages(model.collection.products, {pageSize: 20})

       // return first 10
      return model.collection;
      //return model.collection.products;
    });
  }

  getProductsInCollectionByQuery(collectionId) {
    var query = `{
            node(id: "` + collectionId + `") {
              ... on Collection {
                id
                title
                description
                descriptionHtml
                products(first: 250) {
                  edges {
                    node {
                      title
                      id
                      tags
                      variants(first: 250) {
                        edges {
                          node {
                            compareAtPrice
                            price
                          }
                        }
                      }
                      images(first: 250) {
                        edges {
                          node {
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `;

      return $.ajax({
          method: "POST",
          url: environment.urljson,
          contentType: "application/graphql",
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
          },
          data: query
      })
  }

  getTags() {
      return $.ajax({
          method: "POST",
          url: environment.urljson,
          contentType: "application/json",
          headers: {
            'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
          },
          data: JSON.stringify({
            query: `{
              productTags(first: 10) {
                edges {
                  node
                }
              }
            }`
          })
    })

  }

  getProductTags(): Promise<any> {

    let query = this.client.query((root) => {
        root.add('productTags', { args: { first: 250 } }, (tags) => {
          tags.add('node');
        })
    });

    return this.client.send(query).then(({model, data}) => {
      return model.productTags;
    });

  }

  //Login
  customerAccessTokenCreate(_email,_password): Promise<any> {

    // let _email = "krimeshraiyani1991@gmail.com";
    // let _password = "123456";

    const input = this.client.variable('input', 'CustomerAccessTokenCreateInput!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('customerAccessTokenCreate', { args: { input } }, (customerAccessTokenCreate) => {
        customerAccessTokenCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        customerAccessTokenCreate.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt')
        })

      })
    })
    return this.client.send(mutation, { 'input': { email: _email, password : _password } });
  }

  // New User Create
  customerCreate(_firstName,_lastName,_email,_password): Promise<any> {
    // let _email = "krimeshraiyani1991@gmail.com";
    // let _password = "123456";
    const input = this.client.variable('input', 'CustomerCreateInput!');
    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('customerCreate', { args: { input } }, (customerCreate) => {
        customerCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        customerCreate.add('customer', (customer) => {
          customer.add('id')
        })
      })
    })
    return this.client.send(mutation, { 'input': {firstName:_firstName, lastName:_lastName ,email: _email,password : _password} });
  }

  // Update User
  customerUpdate(_token,_firstName,_lastName,_phone,_Subscribe): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const customer = this.client.variable('customer', 'CustomerUpdateInput!');

    const mutation = this.client.mutation('myMutation', [customerAccessToken,customer], (root) => {
      root.add('customerUpdate', { args: { customerAccessToken,customer } }, (customerUpdate) => {
        customerUpdate.add('customer', (customer) => {
          customer.add('id')
        }),
        // customerUpdate.add('customerAccessToken', (customerAccessToken) => {
        //   customerAccessToken.add('accessToken'),
        //   customerAccessToken.add('expiresAt')
        // }),
        customerUpdate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
          userErrors.add('field')
        })
      })
    })
    //,acceptsMarketing :_Subscribe
    return this.client.send(mutation, { 'customerAccessToken':_token,'customer': {firstName:_firstName, lastName:_lastName ,phone: _phone} });
  }

  //Forget Password
  customerRecover(_email): Promise<any> {

    const email = this.client.variable('email', 'String!');

    const mutation = this.client.mutation('myMutation', [email], (root) => {
      root.add('customerRecover', { args: { email } }, (customerRecover) => {
        customerRecover.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
      })
    })
    return this.client.send(mutation, { 'email': _email });
  }

  // Reset Password
  customerReset(_id,_resetToken,_password): Promise<any> {
    // let _email = "krimeshraiyani1991@gmail.com";
    // let _password = "123456";
    const id = this.client.variable('id', 'ID!');
    const input = this.client.variable('input', 'CustomerResetInput!');

    const mutation = this.client.mutation('myMutation', [id,input], (root) => {
      root.add('customerReset', { args: { id,input } }, (customerReset) => {
        customerReset.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        customerReset.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt')
        })
        customerReset.add('customer', (customer) => {
          customer.add('id')
        })
      })
    })
    return this.client.send(mutation, { 'id': _id,'input': {resetToken:_resetToken, password:_password} });
  }

  // Reset Password By Url
  customerResetByUrl(_url,_password): Promise<any> {
    // let _email = "krimeshraiyani1991@gmail.com";
    // let _password = "123456";
    const resetUrl = this.client.variable('resetUrl', 'URL!');
    const password = this.client.variable('password', 'String!');

    const mutation = this.client.mutation('myMutation', [resetUrl,password], (root) => {
      root.add('customerResetByUrl', { args: { resetUrl,password } }, (customerReset) => {
        customerReset.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        customerReset.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt')
        })
        customerReset.add('customer', (customer) => {
          customer.add('id')
        })
      })
    })
    return this.client.send(mutation, { 'resetUrl': _url, 'password': _password });
  }

  fetchOrders(_token): Promise<any> {

    let query = this.client.query((root) => {
        root.add('customer', { args: { customerAccessToken: _token } }, (customer) => {
          customer.add('id');
          customer.addConnection('orders', {args: {first: 250}}, (orders) => {
            orders.add('id');
            //orders.add('name');
            orders.add('orderNumber');
            //orders.add('phone');
            orders.add('customerUrl');
            //orders.add('cancelReason');
            //orders.add('canceledAt');
            orders.add('currencyCode');
            //orders.add('email');
            //orders.add('financialStatus');
            //orders.add('fulfillmentStatus');
            orders.add('processedAt');
            orders.add('totalPrice');
            orders.add('totalRefunded');
            orders.add('totalShippingPrice');
            orders.add('totalTax');
            // orders.addConnection('currentSubtotalPrice', {args: {first: 1}}, (subtotalPrice) => {
            //   subtotalPrice.add('amount');
            //   subtotalPrice.add('currencyCode');
            // })
            // orders.addConnection('currentTotalPrice', {args: {first: 1}}, (totalPrice) => {
            //   totalPrice.add('amount');
            //   totalPrice.add('currencyCode');
            // })
            // orders.addConnection('currentTotalTax', {args: {first: 1}}, (totalTax) => {
            //   totalTax.add('amount');
            //   totalTax.add('currencyCode');
            // })
            // orders.addConnection('shippingAddress', {args: {first: 1}}, (address) => {
            //   address.add('address1');
            //   address.add('address2');
            //   address.add('city');
            //   address.add('company');
            //   address.add('country');
            //   //address.add('countryCode');
            //   //address.add('countryCodeV2');
            //   address.add('formatted');
            //   address.add('firstName');
            //   address.add('id');
            //   address.add('lastName');
            //   address.add('name');
            //   address.add('phone');
            //   address.add('zip');
            // })

        })
        })
    });

    return this.client.send(query).then(({model, data}) => {
      return model.customer.orders;
    });

  }

  getOrderDetail(id) {
    var query = `{
            node(id: "`+id+`") {
              ... on Order {
                id
                email
                name
                orderNumber
                phone
                shippingAddress {
                  address1
                  address2
                  city
                  country
                  company
                  countryCode
                  firstName
                  id
                  lastName
                  name
                  phone
                  zip
                  province
                }
                lineItems(first:250) {
                  edges {
                    node {
                      title
                      quantity
                      customAttributes {
                        key
                        value
                      }
                      variant {
                        compareAtPrice
                        price
                        image {
                          originalSrc
                          id
                          src
                        }
                        product {
                          id
                        }
                      }
                    }
                  }
                }
                subtotalPrice
                totalPrice
                totalRefunded
                totalShippingPrice
                totalTax
                statusUrl
                customerUrl
                processedAt
              }
            }
          }
          `;

      return $.ajax({
          method: "POST",
          url: environment.urljson,
          contentType: "application/graphql",
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
          },
          data: query
      })
  }

  testCard(){

    var carddetail = {
      "credit_card": {
        "number": "1",
        "first_name": "Krimesh",
        "last_name": "Patel",
        "month": "5",
        "year": "22",
        "verification_value": "123"
      }
    }
    return $.ajax({
      method: "POST",
      url: "https://elb.deposit.shopifycs.com/sessions",
      contentType: "application/json",
      headers: {
        "Access-Control-Origin": "*",
        'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
      },
      data: JSON.stringify(carddetail)
    })
  }

  fetchaddress(_token): Promise<any> {

    let query = this.client.query((root) => {
        root.add('customer', { args: { customerAccessToken: _token } }, (customer) => {
          customer.add('id');
          customer.addConnection('addresses', {args: {first: 250}}, (address) => {
              address.add('address1');
              address.add('address2');
              address.add('city');
              address.add('company');
              address.add('country');
              address.add('province');
              address.add('countryCode');
              address.add('formatted');
              address.add('firstName');
              address.add('id');
              address.add('lastName');
              address.add('name');
              address.add('phone');
              address.add('zip');
        })
        })
    });

    return this.client.send(query).then(({model, data}) => {
      return model.customer.addresses;
    });

  }

  fetchcustomer(_token): Promise<any> {

    let query = this.client.query((root) => {
        root.add('customer', { args: { customerAccessToken: _token } }, (customer) => {
          customer.add('id');
          customer.add('firstName');
          customer.add('lastName');
          customer.add('displayName');
          customer.add('email');
          customer.add('phone');
        })
    });

    return this.client.send(query).then(({model, data}) => {
      return model.customer;
    });

  }

  customerAddressCreate(_token,_address:Address): Promise<any> {
     const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const address = this.client.variable('address', 'MailingAddressInput!');

    const mutation = this.client.mutation('myMutation', [customerAccessToken,address], (root) => {
      root.add('customerAddressCreate', { args: { customerAccessToken,address } }, (customerAddressCreate) => {
        customerAddressCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        customerAddressCreate.add('customerAddress', (customerAddress) => {
          customerAddress.add('id')
        })
      })
    })

    var add = {
      address1: _address.address1,
      address2:_address.address2,
      city:_address.city,
      province:_address.province,
      company :_address.company,
      country :_address.country,
      firstName :_address.firstName,
      lastName :_address.lastName,
      phone : _address.phone,
      zip : _address.zip
    };
    return this.client.send(mutation, {'customerAccessToken': _token, 'address': add });
  }

  customerAddressEdit(_token,_id,_address:Address): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const id = this.client.variable('id', 'ID!');
   const address = this.client.variable('address', 'MailingAddressInput!');

   const mutation = this.client.mutation('myMutation', [customerAccessToken,id,address], (root) => {
     root.add('customerAddressUpdate', { args: { customerAccessToken,id,address } }, (customerAddressUpdate) => {
      customerAddressUpdate.add('userErrors', (userErrors) => {
         userErrors.add('message'),
           userErrors.add('field')
       })
       customerAddressUpdate.add('customerAddress', (customerAddress) => {
         customerAddress.add('id')
       })
     })
   })

   var add = {
     address1: _address.address1,
     address2:_address.address2,
     city:_address.city,
     province:_address.province,
     company :_address.company,
     country :_address.country,
     firstName :_address.firstName,
     lastName :_address.lastName,
     phone : _address.phone,
     zip : _address.zip
   };
   return this.client.send(mutation, {'customerAccessToken': _token,'id':_id, 'address': add });
 }

  customerAddressRemove(_token,_id): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
   const id = this.client.variable('id', 'ID!');

   const mutation = this.client.mutation('myMutation', [id,customerAccessToken], (root) => {
     root.add('customerAddressDelete', { args: { id,customerAccessToken } }, (customerAddressDelete) => {
      customerAddressDelete.add('deletedCustomerAddressId'),
      customerAddressDelete.add('userErrors', (userErrors) => {
         userErrors.add('message'),
           userErrors.add('field')
       })

     })
   })


   return this.client.send(mutation, {'customerAccessToken': _token, 'id': _id });
 }

  createCheckout(_lineItems): Promise<any> {

    const _lineItemsForCheckout = _lineItems.map(item => { return { 'variantId': item.variantId, 'quantity': item.quantity } })

    const input = this.client.variable('input', 'CheckoutCreateInput!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCreate', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        checkoutCreate.add('checkout', (checkout) => {
            checkout.add('id'),
            checkout.add('webUrl'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {

              lineItems.add('variant', (variant) => {
                variant.add('title')
              }),
                lineItems.add('quantity')
            }
            )
        })
      })
    })
    return this.client.send(mutation, { 'input': { lineItems: _lineItemsForCheckout } });
  }

  checkoutCompleteFree(_checkoutId): Promise<any> {
    const input = this.client.variable('checkoutId', 'ID!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCompleteFree', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        checkoutCreate.add('checkout', (checkout) => {
            checkout.add('id')
        })
      })
    })
    return this.client.send(mutation, {checkoutId: _checkoutId});
  }

  createCheckout_Old(_lineItems): Promise<any> {

    const _lineItemsForCheckout = _lineItems.map(item => { return { 'variantId': item.variantId, 'quantity': item.quantity } })

    const input = this.client.variable('input', 'CheckoutCreateInput!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCreate', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field')
        })
        checkoutCreate.add('checkout', (checkout) => {
          checkout.add('id'),
            checkout.add('webUrl'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {

              lineItems.add('variant', (variant) => {
                variant.add('title')
              }),
                lineItems.add('quantity')
            }
            )
        })
      })
    })
    return this.client.send(mutation, { 'input': { lineItems: _lineItemsForCheckout } });
  }

  fetchCheckout(_checkoutid): Promise<any> {

    const id = this.client.variable('checkoutId', 'ID!');

    let query = this.client.query((root) => {
      root.add('node', { args: { id: _checkoutid }, alias: 'checkout' }, (node) => {
        node.add('id');
        node.addInlineFragmentOn('Checkout', (Checkout) => {
          Checkout.add('webUrl');
          Checkout.add('email');
          Checkout.add('subtotalPrice'),
          Checkout.add('totalTax'),
          Checkout.add('totalPrice'),
          Checkout.add('shippingAddress', (shippingAddress) => {
            shippingAddress.add('address1'),
            shippingAddress.add('address2'),
            shippingAddress.add('city'),
            shippingAddress.add('company'),
            shippingAddress.add('country'),
            shippingAddress.add('firstName'),
            shippingAddress.add('lastName'),
            shippingAddress.add('phone'),
            shippingAddress.add('zip'),
            shippingAddress.add('province')
          }),
          Checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
            lineItems.add('title'),
            lineItems.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price')
              }),
              lineItems.add('quantity')
            })
          })
        })
      });

    return this.client.send(query, { checkoutId: _checkoutid })
  }

  checkoutDiscountCodeApply(_discountCode,_checkoutId): Promise<any> {
    const discountCode = this.client.variable('discountCode', 'String!');
    const checkoutId = this.client.variable('checkoutId', 'ID!');

    const mutation = this.client.mutation('myMutation', [discountCode,checkoutId], (root) => {
      root.add('checkoutDiscountCodeApply', { args: { discountCode,checkoutId } }, (discountCodeApply) => {
        discountCodeApply.add('userErrors', (userErrors) => {
            userErrors.add('message'),
            userErrors.add('field')
        })
        discountCodeApply.add('checkout', (checkout) => {
          checkout.add('id')
        })
      })
    })


   return this.client.send(mutation, {'discountCode': _discountCode, 'checkoutId': _checkoutId });
 }

 checkoutDiscountCodeApplyQuery(_discountCode,_checkoutId): Promise<any> {
  var query = ` mutation {
              checkoutDiscountCodeApply(discountCode: "`+_discountCode+`", checkoutId: "`+_checkoutId+`") {
                checkout {
                  id
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `;

          return $.ajax({
            method: "POST",
            url: environment.urljson,
            contentType: "application/graphql",
            headers: {
              'Content-Type': 'application/graphql',
              'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
            },
            data: query
          })
}

 checkoutDiscountCodeRemove(_checkoutId): Promise<any> {
 const checkoutId = this.client.variable('checkoutId', 'ID!');

 const mutation = this.client.mutation('myMutation', [checkoutId], (root) => {
   root.add('checkoutDiscountCodeRemove', { args: { checkoutId } }, (checkoutDiscountCodeRemove) => {
    checkoutDiscountCodeRemove.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field')
     })
     checkoutDiscountCodeRemove.add('checkout', (checkout) => {
      checkout.add('id')
     })
   })
 })


 return this.client.send(mutation, {'checkoutId': _checkoutId });
 }

 checkoutDiscountCodeRemoveQuery(_checkoutId): Promise<any> {
  var query = ` mutation {
    checkoutDiscountCodeRemove(checkoutId: "`+_checkoutId+`") {
                checkout {
                  id
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `;

          return $.ajax({
            method: "POST",
            url: environment.urljson,
            contentType: "application/graphql",
            headers: {
              'Content-Type': 'application/graphql',
              'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
            },
            data: query
          })
}

 checkoutEmailUpdate(_email,_checkoutId): Promise<any> {
  const email = this.client.variable('email', 'String!');
 const checkoutId = this.client.variable('checkoutId', 'ID!');

 const mutation = this.client.mutation('myMutation', [email,checkoutId], (root) => {
   root.add('checkoutEmailUpdate', { args: { email,checkoutId } }, (checkoutEmailUpdate) => {
    checkoutEmailUpdate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field')
     })
     checkoutEmailUpdate.add('checkout', (checkout) => {
      checkout.add('id')
     })
   })
 })


 return this.client.send(mutation, {'email': _email, 'checkoutId': _checkoutId });
}

checkoutCustomerAssociate(_customerAccessToken,_checkoutId): Promise<any> {
  const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
 const checkoutId = this.client.variable('checkoutId', 'ID!');

 const mutation = this.client.mutation('myMutation', [customerAccessToken,checkoutId], (root) => {
   root.add('checkoutCustomerAssociate', { args: { customerAccessToken,checkoutId } }, (checkoutEmailUpdate) => {
    checkoutEmailUpdate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field')
     })
     checkoutEmailUpdate.add('checkout', (checkout) => {
      checkout.add('id')
     })
     checkoutEmailUpdate.add('customer', (customer) => {
      customer.add('id')
     })
   })
 })


 return this.client.send(mutation, {'customerAccessToken': _customerAccessToken, 'checkoutId': _checkoutId });
}

checkoutCustomerDisassociate(_checkoutId): Promise<any> {
  const checkoutId = this.client.variable('checkoutId', 'ID!');

 const mutation = this.client.mutation('myMutation', [checkoutId], (root) => {
   root.add('checkoutCustomerDisassociate', { args: { checkoutId } }, (checkoutCustomerDisassociate) => {
    checkoutCustomerDisassociate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field')
     })
     checkoutCustomerDisassociate.add('checkout', (checkout) => {
      checkout.add('id')
     })
   })
 })


 return this.client.send(mutation, {'checkoutId': _checkoutId });
}

checkoutShippingAddressUpdate(_checkoutId,_shippingAddress:Address): Promise<any> {
  const checkoutId = this.client.variable('checkoutId', 'ID!');
 const shippingAddress = this.client.variable('shippingAddress', 'MailingAddressInput!');

 const mutation = this.client.mutation('myMutation', [shippingAddress,checkoutId], (root) => {
   root.add('checkoutShippingAddressUpdate', { args: { shippingAddress,checkoutId } }, (ShippingAddressCreate) => {
    ShippingAddressCreate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field')
     })
     ShippingAddressCreate.add('checkout', (customerAddress) => {
       customerAddress.add('id')
     })
   })
 })

 var add = {
   address1: _shippingAddress.address1,
   address2:_shippingAddress.address2,
   city:_shippingAddress.city,
   province:_shippingAddress.province,
   company :_shippingAddress.company,
   country :_shippingAddress.country,
   firstName :_shippingAddress.firstName,
   lastName :_shippingAddress.lastName,
   phone : _shippingAddress.phone,
   zip : _shippingAddress.zip
 };
 return this.client.send(mutation, {'checkoutId': _checkoutId, 'shippingAddress': add });
}

  addVariantsToCheckout(_checkoutid, _lineItems): Promise<any> {

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const _lineItemsForCheckout = _lineItems.map(item => { return { 'id': item.id, 'variantId': item.variantId, 'quantity': item.quantity } })
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemInput!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root) => {
      root.add('checkoutLineItemsAdd', { args: { checkoutId, lineItems } }, (checkoutLineItemsAdd) => {

        checkoutLineItemsAdd.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });

        checkoutLineItemsAdd.add('checkout', (checkout) => {
          checkout.add('webUrl'),
            checkout.add('subtotalPrice'),
            checkout.add('totalTax'),
            checkout.add('totalPrice'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
              lineItems.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price')
              }),
                lineItems.add('quantity')
            })
        })
      })
    });

    return this.client.send(mutation, { checkoutId: _checkoutid, lineItems: _lineItemsForCheckout });

  }

  removeLineItem(_checkoutid, _lineItemId): Promise<any> {

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItemIds = this.client.variable('lineItemIds', '[ID!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItemIds], (root) => {
      root.add('checkoutLineItemsRemove', { args: { checkoutId, lineItemIds } }, (checkoutLineItemsRemove) => {

        checkoutLineItemsRemove.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        }),

          checkoutLineItemsRemove.add('checkout', (checkout) => {
            checkout.add('webUrl'),
              checkout.add('subtotalPrice'),
              checkout.add('totalTax'),
              checkout.add('totalPrice'),
              checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
                lineItems.add('variant', (variant) => {
                  variant.add('title'),
                    variant.add('image', (image) => image.add('src')),
                    variant.add('price')
                }),
                  lineItems.add('quantity')
              })
          })
      })
    });

    return this.client.send(mutation, { checkoutId: _checkoutid, lineItemIds: [_lineItemId] });

  }

  updateLineItem(_checkoutid, _lineItems): Promise<any> {
    const _lineItemsForCheckout = _lineItems.map(item => { return { 'id': item.id, 'variantId': item.variantId, 'quantity': item.quantity } })

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemUpdateInput!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root) => {
      root.add('checkoutLineItemsUpdate', { args: { checkoutId, lineItems } }, (checkoutLineItemsUpdate) => {

        checkoutLineItemsUpdate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });

        checkoutLineItemsUpdate.add('checkout', (checkout) => {
          checkout.add('webUrl'),
            checkout.add('subtotalPrice'),
            checkout.add('totalTax'),
            checkout.add('totalPrice'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
              lineItems.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price')
              }),
                lineItems.add('quantity')
            })
        })
      })
    });

    return this.client.send(mutation, { checkoutId: _checkoutid, lineItems: _lineItemsForCheckout });
  }

  SendMail(obj: any): Observable<any> {
    var mailgunApiKey = window.btoa("api:"+environment.MailgunAPIKEY);
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      "Authorization" : "Basic " + mailgunApiKey
    });
    console.log(mailgunApiKey);
    httpHeaders.append('Authorization',"Basic " + mailgunApiKey);
    let options = {
      headers: httpHeaders
    };
    //var url = "https://api.mailgun.net/v3/valleyrvc.org/messages";
    var url = "https://api.mailgun.net/v3/"+environment.MailgunDomain+"/messages";
    return this.http.post(url, obj,options);
  }

}
