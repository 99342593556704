// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://family-fraternal-sales-ltd.myshopify.com/api/graphql',
  urljson: 'https://family-fraternal-sales-ltd.myshopify.com/api/graphql.json',
  shopifyaccesstoken: '14cdfdca830171c181fee20ff1fdb8b5',
  shopifydomain:"https://family-fraternal-sales-ltd.myshopify.com",
  adminurl:"https://0120fc43547a3877bd94c08be9d9a281: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@family-fraternal-sales-ltd.myshopify.com",
  shopifyAPIkey:"0120fc43547a3877bd94c08be9d9a281",
  shopifypassword:"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",
  shopifyshop:"Family Fraternal Sales, LTD",
  timeout:3000,
  MailgunDomain:"sandbox969d0e00153445d6add9899f1032c2a1.mailgun.org",
  MailgunAPIKEY:"key-ca51af067e7398ab0ee2e15e809a2653",
  EmailFrom:"nonreply@familyfraternity.com",
  EmailTo:"family.fraternal@gmail.com",
  ShopifyAdminlink:"https://admin.familyfraternal.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
