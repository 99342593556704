<div class="contactUsPage">
    <div class="innerTopBanner">
        <img class="img" src="assets/images/contact-us-banner.jpg" alt="">
    </div>
    <div class="container">
        <div class="contactPageBox">
            <h1>Get in touch with us!</h1>
            <div class="desc"></div>
            <div class="row">
                <div class="col">
                    <form name="form" id="form" #form="ngForm" class=" form-validation">
                        <div class="customForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="inputfield icon">
                                        <input class="input" name="firstName" id="firstName" [(ngModel)]="firstName" (change)="FocusOutEvent($event)" type="text" required>
                                        <span class="error">Please Fill Your First Name</span>
                                        <label>First name*</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="inputfield icon">
                                        <input class="input" name="lastName" id="lastName" [(ngModel)]="lastName" (change)="FocusOutEvent($event)" type="text" required>
                                        <label>Last name*</label>
                                    </div>
                                </div>
                            </div>
                            <div class="inputfield icon">
                                <input class="input" name="Email" id="Email" [(ngModel)]="Email" (change)="FocusOutEvent($event)" type="text" required>
                                <label>Email*</label>
                            </div>
                            <div class="inputfield icon">
                                <textarea class="input" id="Message" name="Message" [(ngModel)]="Message" (change)="FocusOutEvent($event)" required></textarea>
                                <label>Message*</label>
                            </div>
                            <div class="btnRows">
                                <button type="button" class="redBtn" (click)="OnSubmit()" [disabled]="form.form.invalid">Submit</button>
                            </div>
                        </div>
                    </form>



                </div>
                <div class="col nedHelpCol">
                    <div class="helpBox">
                        <img src="images/help-img.jpg" alt="">
                        <h3>Need Help?</h3>
                        <div class="text">Call
                            <br>
                            <a class="tel" href="tel:866-637-7253">866-637-7253</a>
                            <br>OR
                            <a class="tel" href="tel:516-826-6533">516-826-6533</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <script>
        $(document).ready(function() {
            $(".inputfield .input").focusout(function() {
                if ($(this).val() !== '') {
                    $(this).parent().find('label').addClass('active');
                } else {
                    $(this).parent().find('label').removeClass('active');
                }
            });
        });
    </script>
